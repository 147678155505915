import Image from "next/image";
import {
  ComponentPropsWithoutRef,
  FC,
  ReactElement,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import feature3Image from "../public/images/captions.jpg";
import feature2Image from "../public/images/crop.jpg";
import feature4Image from "../public/images/style.jpg";
import feature1Image from "../public/images/topics.jpg";
import Typography from "./Typography";

type FeaturesSectionProps = {
  sectionTitle?: ReactElement;
  sectionSubtitle?: ReactElement;
} & ComponentPropsWithoutRef<"section">;

const FeaturesSection: FC<FeaturesSectionProps> = ({
  sectionTitle,
  sectionSubtitle,
  ...rest
}) => {
  const features = [
    {
      title: "AI Editing",
      description:
        "AI extract the best topics from the video and edits them into viral short clips",
      image: feature1Image,
    },
    {
      title: "Auto Reframing",
      description:
        "Video is automatically reframed to always focus on the most important part (face detection)",
      image: feature2Image,
    },
    {
      title: "Engaging Captions",
      description:
        "Beautiful dynamic captions are generated to keep the viewer engaged",
      image: feature3Image,
    },
    {
      title: "Customization",
      description:
        "Customize everything (frame, fonts, colors, etc.) to fit your brand",
      image: feature4Image,
    },
  ];

  const [image, setImage] = useState(feature1Image);

  const refs = useRef(features.map(() => createRef<HTMLDivElement>()));

  const handleScroll = () => {
    for (let i = features.length - 1; i >= 0; i--) {
      const rect = refs.current[i].current?.getBoundingClientRect();

      if (!rect) return;
      if (rect.top < window.innerHeight / 2) {
        setImage(features[i].image);
        return; // return early when an image has been set
      }
    }
    setImage(feature1Image); // reset to default image if none of the conditions above are met
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section {...rest} className={`${rest.className}`}>
      <Typography variant="body" className="text-slate-600">
        {sectionSubtitle ?? "Embraced a new area of video editing"}
      </Typography>
      <Typography variant="h3" className="text-center mt-4">
        {sectionTitle ? (
          sectionTitle
        ) : (
          <>
            <span className="text-purple-600">Save hours</span> using AI
          </>
        )}
      </Typography>
      {/* <Typography variant="h3" className="text-center mt-4">
        <span className="text-purple-600">Save hours</span> of video editing
      </Typography> */}
      <div className="flex-col md:flex-row gap-16 mt-16 hidden md:flex">
        <div className="flex-1">
          <div className="sticky top-40 isolate overflow-hidden border-8 rounded-2xl border-slate-950 bg-slate-950">
            {features.map((feature, index) => (
              <Image
                alt={feature.description}
                key={feature.title}
                className={`transition-opacity ${
                  index == 0 ? "" : "absolute inset-0"
                } ${image == feature.image ? "opacity-100" : "opacity-0"}`}
                src={feature.image}
              />
            ))}
          </div>
        </div>
        <div className="flex-1 text-left">
          {features.map((feature, index) => (
            <div
              ref={refs.current[index]}
              className={`my-20 transition-opacity ${
                image == feature.image ? "opacity-100" : "opacity-30"
              }`}
              key={feature.title}
            >
              <Typography variant={"h4"} className="mt-8 flex">
                {feature.title}
              </Typography>
              <Typography
                variant={"body1"}
                className="mt-4 !leading-relaxed text-slate-600"
              >
                {feature.description}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-8 md:hidden mt-16">
        {features.map((feature, index) => (
          <div key={feature.title}>
            <Typography variant="h4" className="mt-8">
              {feature.title}
            </Typography>
            <Typography
              variant="body1"
              className="mt-4 !leading-tight text-slate-600"
            >
              {feature.description}
            </Typography>
            <div className="isolate overflow-hidden rounded-2x border-8 rounded-2xl border-slate-950 mt-4">
              <Image
                alt={feature.description}
                key={feature.title}
                src={feature.image}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Typography from "./Typography";

interface FAQProps {
  className?: string;
  faq?: {
    question: string;
    answer: React.ReactNode;
  }[];
}

const faq = [
  {
    question: "How does Klap work?",
    answer: (
      <>
        Klap utilizes artificial intelligence (AI) to identify interesting
        topics within the uploaded videos. It then generate edited short clips
        based of these topics, with intelligent framing to portrait and engaging
        captions.
      </>
    ),
  },
  {
    question: "What kind of videos are supported?",
    answer: (
      <>
        Klap work on all videos. However, as the algorithm rely on speech, Klap
        works best for talking videos: podcasts, interviews, educational videos,
        product reviews, etc.
      </>
    ),
  },
  {
    question: "What languages are supported?",
    answer: (
      <>
        Klap support 52 languages: Afrikaans, Arabic, Armenian, Azerbaijani,
        Belarusian, Bosnian, Bulgarian, Catalan, Chinese, Croatian, Czech,
        Danish, Dutch, English, Estonian, Finnish, French, Galician, German,
        Greek, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian,
        Japanese, Kannada, Kazakh, Korean, Latvian, Lithuanian, Macedonian,
        Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese,
        Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili,
        Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian, Urdu, Vietnamese, and
        Welsh
      </>
    ),
  },
  {
    question: "Is Klap free?",
    answer: (
      <>
        We offer 1 free trial video for you to try out Klap. After that, you can
        choose to upgrade to Klap Pro starting at $29/month.
      </>
    ),
  },
  {
    question: "I have more questions. How can I contact you?",
    answer: (
      <>
        Feel free to contact us on{" "}
        <a className="text-purple-600" href="https://discord.gg/KcAcHdrSQU">
          Discord
        </a>{" "}
        or by email at{" "}
        <a className="text-purple-600" href="mailto:team@klap.app">
          team@klap.app
        </a>
      </>
    ),
  },
  // {
  //   question: "How long does it take to process a video?",
  //   answer: (
  //     <>
  //       It depends on the length of the video but it usually takes less than 15
  //       minutes.
  //     </>
  //   ),
  // },
  // {
  //   question: "How long can my videos be?",
  //   answer: (
  //     <>
  //       You can upload videos up to 2 hours long using Klap Pro and 30 minutes
  //       long using the free plan. Need longer videos? Contact us at
  //       team@klap.app
  //     </>
  //   ),
  // },
];

const FAQ = (props: FAQProps) => {
  return (
    <section className={`${props.className}`}>
      <Typography className="text-center" variant="h3">
        Frequently Asked Questions
      </Typography>
      <div className="mt-16 font-display w-full flex flex-col text-left items-stretch justify-start gap-12">
        {(props.faq || faq).map((faq) => {
          return (
            <details
              key={faq.question}
              className=" font-light list-none group open:bg-slate-200 rounded-2xl"
            >
              <summary className="text-xl font-normal list-none flex items-center justify-stretch hover:bg-slate-200 p-4 rounded-2xl cursor-pointer">
                <Typography className="text-slate-900" variant="subtitle1">
                  {faq.question}
                </Typography>
                <ChevronDownIcon className="w-6 h-6 ml-auto group-open:rotate-180 shrink-0 text-slate-500" />
              </summary>
              <div className="px-4 pb-4">
                <Typography variant="body2" className="text-slate-600">
                  {faq.answer}
                </Typography>
              </div>
            </details>
          );
        })}
      </div>
    </section>
  );
};

export default FAQ;

import { ComponentPropsWithoutRef, FC } from "react";
import avatar0 from "../public/images/trusted_by/0.jpg";
import avatar1 from "../public/images/trusted_by/1.jpg";
import avatar2 from "../public/images/trusted_by/2.jpg";
import avatar3 from "../public/images/trusted_by/3.jpg";
import avatar4 from "../public/images/trusted_by/4.jpg";
import Avatar from "./Avatar";
import Typography from "./Typography";

type TrustedByProps = {
  variant?: "dark" | "light";
} & ComponentPropsWithoutRef<"div">;

const TrustedBy: FC<TrustedByProps> = ({ variant, ...rest }) => {
  return (
    <div
      {...rest}
      className={`${rest.className} ${
        variant == "dark" ? "text-slate-400" : "text-slate-600"
      } flex flex-col gap-2 `}
    >
      <div className="flex">
        <a
          href="https://twitter.com/twistartups/status/1691488168829190144?s=20"
          target="_blank"
        >
          <Avatar
            src={avatar0}
            className={`w-8 h-8 !bg-black border-2 ${
              variant == "dark" ? "border-slate-950" : "border-slate-100"
            }`}
          />
        </a>
        <a
          href="https://twitter.com/AngryTomtweets/status/1684863926259822592?s=20"
          target="_blank"
          className="-ml-2"
        >
          <Avatar
            src={avatar1}
            className={`w-8 h-8 !bg-black border-2 ${
              variant == "dark" ? "border-slate-950" : "border-slate-100"
            }`}
          />
        </a>
        <a
          href="https://twitter.com/ElCopyMaster/status/1694401220612431939?s=20"
          target="_blank"
          className="-ml-2"
        >
          <Avatar
            src={avatar2}
            className={`w-8 h-8 !bg-black border-2 ${
              variant == "dark" ? "border-slate-950" : "border-slate-100"
            }`}
          />
        </a>
        <a
          href="https://twitter.com/MakadiaHarsh/status/1692575229220897195?s=20"
          target="_blank"
          className="-ml-2"
        >
          <Avatar
            src={avatar3}
            className={`w-8 h-8 !bg-black border-2 ${
              variant == "dark" ? "border-slate-950" : "border-slate-100"
            }`}
          />
        </a>
        <a
          href="https://twitter.com/riyaa1337/status/1692836741684789437?s=20"
          target="_blank"
          className="-ml-2"
        >
          <Avatar
            src={avatar4}
            className={`w-8 h-8 !bg-black border-2 ${
              variant == "dark" ? "border-slate-950" : "border-slate-100"
            }`}
          />
        </a>
      </div>
      <Typography variant="caption" className="text-left">
        Trusted by 350k+ happy users
      </Typography>
    </div>
  );
};

export default TrustedBy;

import { ArrowUpRightIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { ComponentPropsWithoutRef, FC } from "react";
import { DISCORD_LINK } from "./Nav";
import Typography from "./Typography";

type FooterProps = {} & ComponentPropsWithoutRef<"footer">;

const Footer: FC<FooterProps> = ({ ...rest }) => {
  const posthog = usePostHog();
  return (
    <footer
      {...rest}
      className={`${rest.className} py-16 px-3 max-w-5xl text-center mx-auto`}
    >
      <Typography className=" text-slate-700">
        Made with ♥ by{" "}
        <a
          className="text-slate-500"
          href="https://twitter.com/deletethistheo"
          target="_blank"
          rel="noreferrer"
        >
          @theo
        </a>{" "}
        and{" "}
        <a
          className="text-slate-500"
          href="https://twitter.com/VictorTimsit"
          target="_blank"
          rel="noreferrer"
        >
          @victor
        </a>
      </Typography>

      <div className="mt-16 text-slate-700 flex flex-col md:flex-row flex-wrap gap-16 justify-between text-left">
        {/* <div>
          <Typography variant="caption" className="font-medium">
            Business Registry Number
          </Typography>
          <Typography variant="caption" className="">
            SIRET: 88806141300017
          </Typography>
          <Typography variant="caption" className="">
            SIREN (HQ): 888061413
          </Typography>
        </div>

        <div>
          <Typography variant="caption" className="font-medium">
            Business Address
          </Typography>
          <Typography variant="caption" className="">
            65 rue Roger François
          </Typography>
          <Typography variant="caption" className="">
            94700, Maisons-Alfort, France
          </Typography>
        </div> */}
        <div className="flex flex-col gap-1 items-baseline justify-start text-slate-700 text-sm flex-1">
          <Typography variant="subtitle3">
            ZIGG SAS doing business as Klap
          </Typography>

          <Link href="/terms-of-services" className="text-slate-500">
            Terms of services
          </Link>
          <Link href="/privacy-policy" className="text-slate-500">
            Privacy policy
          </Link>
        </div>
        <div className="flex flex-col gap-1 items-baseline justify-start text-slate-700 text-sm">
          <Typography variant="subtitle3">Resources</Typography>
          <Link href="/blog" className="text-slate-500">
            Blog
          </Link>
          <Link href="/briefs" className="text-slate-500">
            Briefs
          </Link>
          <Link
            target="_blank"
            href="https://docs.klap.app"
            className="text-slate-500 flex items-center justify-center gap-1"
          >
            <span>API Docs</span>
            <ArrowUpRightIcon className="w-4 h-4" />
          </Link>
          <Link
            target="_blank"
            href={DISCORD_LINK}
            className="text-slate-500 flex items-center justify-center gap-1"
          >
            <span>Discord</span>
            <ArrowUpRightIcon className="w-4 h-4" />
          </Link>
        </div>
        <div className="flex flex-col gap-1 items-baseline justify-start text-slate-700 text-sm">
          <Typography variant="subtitle3">Services</Typography>
          <Link href="/affiliate" className="text-slate-500">
            Become an affiliate
          </Link>
          <Link href="/rest-api" className="text-slate-500">
            API
          </Link>
        </div>
        <div className="flex flex-col gap-1 items-baseline justify-center text-slate-700 text-sm">
          <Typography variant="subtitle3">Alternatives</Typography>
          <Link href="/alternatives/2short-ai" className="text-slate-500">
            2short AI
          </Link>
          <Link href="/alternatives/dumme" className="text-slate-500">
            Dumme
          </Link>
          <Link href="/alternatives/munch" className="text-slate-500">
            Munch
          </Link>
          <Link href="/alternatives/opus-clip" className="text-slate-500">
            Opus Clip
          </Link>
          <Link href="/alternatives/qlip-ai" className="text-slate-500">
            Qlip
          </Link>
          <Link href="/alternatives/vidyo-ai" className="text-slate-500">
            Vidyo
          </Link>
          <Link href="/alternatives/vizard-ai" className="text-slate-500">
            Vizard AI
          </Link>
          <Link href="/alternatives/veed-io" className="text-slate-500">
            Veed.io
          </Link>
          <Link href="/alternatives/submagic" className="text-slate-500">
            Submagic
          </Link>
          <Link href="/alternatives/zubtitle" className="text-slate-500">
            Zubtitle
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
